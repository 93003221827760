<template>
  <div class="edit">
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog">
      <el-form ref="form" :model="form" label-width="80px" class="form" :rules="rules" hide-required-asterisk>
        <el-form-item label="审核状态" prop="exam">
          <el-select v-model="form.exam" placeholder="选择状态" @change="statusChange">
            <el-option
              v-for="item in examOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因:" prop="reason" v-if="this.form.exam == 2 || this.form.exam == -1">
          <el-select v-model="form.reason" placeholder="请选择" style="width:100%" @change="selectChange">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他原因:" prop="otherReason" v-if="this.form.reason == 'other'">
          <el-input v-model="form.otherReason" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分配客服" prop="auditId">
          <el-select v-model="form.auditId" placeholder="选择客服">
            <el-option
              v-for="item in auditOpts"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="黑名单" prop="isBlack">-->
        <!--          <el-select v-model="form.isBlack" placeholder="请选择">-->
        <!--            <el-option-->
        <!--              v-for="item in blackOpts"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            ></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: { exam: null, auditId: null, reason:null, otherReason:'' },
      rules:{
        reason:[{required: true, message:'请选择原因', trigger:'change'}],
        otherReason:[{required: true, message:'请选择原因', trigger:'change'}],
      },
      examOpts: [],
      blackOpts: [
        { value: 0, label: '请选择' },
        { value: 1, label: '加入到黑名单' }
      ],
      auditOpts: [],
      options:[],
      blackTag:[],
      width: '40%'
    };
  },
  computed: {
    title() {
      let { username = '', mobile = ''} = this.editData;
      return username ? '编辑用户 -' + username : '编辑用户 - ' + mobile;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.$refs['form'].resetFields();
    },
    submit() {
      let check = false;
      this.$refs.form.validate(res => check = res)
      if(!check) return;
      let str = '';
      if(this.form.reason == 'other'){
        str = this.form.otherReason;
      }else{
        str = this.form.reason;
      }
      this.$emit('submit', {exam:this.form.exam, auditId:this.form.auditId, reason:str});
      this.$refs['form'].resetFields();
    },
    openDialog() {
      this.$nextTick(() => {
        this.form.exam = this.editData.status;
        if(this.form.exam == 0){
          this.examOpts = [
            { value: 0, label: '未处理' },
            { value: 1, label: '通过' },
            { value: 2, label: '拒绝' },
            { value: -1, label: '黑名单' }
          ]
        }else{
          this.examOpts = [
            { value: 1, label: '通过' },
            { value: 2, label: '拒绝' },
            { value: -1, label: '黑名单' }
          ]
        };
        this.form.auditId = this.auditOpts.filter(item => item.username == this.editData.customer)[0].id || null;
      });
      this.getReasons();
    },
    getCustomerOpts() {
      return service.customerOpts().then(res => {
        let { list = [] } = res;
        this.auditOpts = list.map(item => ({ id: item.id, username: item.username }));
      });
    },
    getReasons(){
      service.getRefuseOptions().then(res => {
        let list = [];
        res.sys_reject_reason.split(',').map(item => {
          list.push({value: item, label: item})
        })
        list.push({value:'other', label:'其他'})
        this.options = list;
        if(this.editData.reason){
          this.initReason(res.sys_reject_reason);
        }else{
          this.form.reason = '';
          this.form.otherReason = '';
        }
      } )
    },
    initReason(arr){
      if(arr.indexOf(this.editData.reason) > -1){
        this.form.reason = this.editData.reason;
        this.form.otherReason = '';
      }else{
        this.form.reason = 'other';
        this.form.otherReason = this.editData.reason;
      }
    },
    statusChange(){
      this.form.reason = '';
      this.form.otherReason = '';
      this.$refs.form.clearValidate();
    },
    selectChange(){
      this.form.otherReason = '';
    }
  },
  created() {
    this.getCustomerOpts();
     if(document.documentElement.clientWidth < 480){
       this.width = '80%'
     }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  .form {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    .el-select {
      width: 100%;
    }
  }
}

</style>
